<template>
  <el-card v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Configurações do Sistema</h4>
      </el-row>
    </template>
    <el-row :gutter="8" type="flex" justify="between" align="center">
      <el-col :gutter="8" :md="12" :sm="24">
        <el-card>
          <template #header>
            <el-row type="flex" justify="space-between" align="center">
              <h4>Contatos para alertas do sistema</h4>
              <el-col :md="8">
                <el-row type="flex" justify="end" align="center">
                  <el-button type="primary" icon="el-icon-edit" class="m-auto" size="small"
                    @click="openContactPreferenceModal(null)"></el-button>
                </el-row>
              </el-col>
            </el-row>
          </template>
          <el-table :cell-style="() => 'text-align:center;'" :data="SelectedContacts">
            <el-table-column prop="name" label="nome"></el-table-column>
            <el-table-column prop="phone" label="telefone"></el-table-column>
          </el-table>
        </el-card>
      </el-col>

      <el-col :gutter="8" :md="12" :sm="24">
        <el-card>
          <template #header>
            <el-row type="flex" justify="space-between" align="center">
              <h4>Qualidade da carne</h4>
              <el-col :md="8">
                <el-row type="flex" justify="end" align="center">
                  <el-button type="primary" icon="el-icon-edit" class="m-auto" size="small"
                    @click="openPreferenceInputModal(null)"></el-button>
                </el-row>
              </el-col>
            </el-row>
          </template>
          <el-table :data="DefinedStandards">
            <el-table-column prop="value" label="nome"></el-table-column>
          </el-table> </el-card></el-col>
    </el-row>

    <el-row :gutter="8" type="flex" justify="between" align="center">
      <el-col :gutter="8" :md="12" :sm="24">
        <el-card>
          <template #header>
            <el-row type="flex" justify="start">
              <h4>Chaves de acesso externas</h4>
            </el-row>
            <el-row type="flex" justify="start" style="margin-top: -30px">
              <h6 class="text-muted">Pressione enter para atualizar</h6>
            </el-row>
          </template>
          <div>
            <base-input label="CNPJA:" type="text" v-model="getPreferenceByToken('CNPJA_TOKEN').value" v-on:keyup.enter="
              setPreference(getPreferenceByToken('CNPJA_TOKEN'))
              "></base-input>
            <base-input label="Docker:" type="text" v-model="getPreferenceByToken('DOCKER_TOKEN').value"
              v-on:keyup.enter="
                setPreference(getPreferenceByToken('DOCKER_TOKEN'))
                "></base-input>
            <base-input label="Mailgun:" type="text" v-model="getPreferenceByToken('MAILGUN_TOKEN').value"
              v-on:keyup.enter="
                setPreference(getPreferenceByToken('MAILGUN_TOKEN'))
                "></base-input>
            <base-input label="Domínio Mailgun:" type="text" v-model="getPreferenceByToken('MAILGUN_DOMAIN').value"
              v-on:keyup.enter="
                setPreference(getPreferenceByToken('MAILGUN_DOMAIN'))
                "></base-input>
          </div>
        </el-card>
      </el-col>

      <el-col :gutter="8" :md="12" :sm="24">
        <el-card>
          <template #header>
            <el-row type="flex" justify="start">
              <h4>API do WhatsApp</h4>
            </el-row>
          </template>
          <div>
            <el-row type="flex" justify="space-between" width="100%">
              <h5>Estado da API</h5>
              <el-row type="flex" justify="end" class="gap-2">
                <div v-for="(status, id) in APIStatus" :key="APIStatus[id]">
                  <el-tag v-if="id < 1 || canAny('ViewExecutiveReports')" v-loading="!APIStatus[id]"
                    style="cursor: pointer" size="medium" :type="WhatsAppStatusTypes[WhatsAppAPIStatus[id]]"
                    @click="openShowWhatsappLoginModal(id)">{{ WhatsAppAPIStatus[id] }}</el-tag>
                </div>
              </el-row>
            </el-row>
          </div>
        </el-card></el-col>

      <el-col :gutter="8" :md="12" :sm="24">
        <el-card>
          <template #header>
            <el-row type="flex" justify="space-between" align="center">
              <h4>Impressoras</h4>
              <el-col :md="8">
                <el-row type="flex" justify="end" align="center">
                  <el-button type="primary" icon="el-icon-plus" class="m-auto" size="small"
                    @click="openAddPrinter(null)"></el-button>
                </el-row>
              </el-col>
            </el-row>
          </template>
          <el-table :data="Printers">
            <el-table-column prop="name" label="nome"></el-table-column>
            <el-table-column prop="ip" label="ip"></el-table-column>
            <el-table-column label="estado">
              <template #default="{ row: printer }">
                <el-tag v-if="printer?.status === 'active'" type="success">online</el-tag>
                <el-tag v-else type="danger">offline</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="ações">
              <template #default="{ row: printer }">
                <el-row type="flex" justify="end" :style="{ 'margin-right': '8px' }">
                  <el-button-group>
                    <el-button onlyIcon type="primary" plain @click="openEditPrinter(printer)">
                      <i class="el-icon-edit"></i></el-button>
                    <el-button type="primary" plain @click="openEditPrinter(printer, 'ip')">
                      ip
                    </el-button>
                    <el-popconfirm @confirm="deletePrinter(printer)" title="
              Remover impressora?
            "><template #reference>
                        <el-button onlyIcon type="danger" plain>
                          <i class="el-icon-delete"></i></el-button></template></el-popconfirm>
                  </el-button-group>
                </el-row>
              </template>
            </el-table-column>
          </el-table> </el-card></el-col>
    </el-row>
    <system-preference-contacts-modal :showModal="showContactPreferenceModal" :contacts="contacts"
      :selectedContacts="SelectedContactsGroupedByChat" @close-modal="showContactPreferenceModal = false"
      @select-contact="selectContact($event)"></system-preference-contacts-modal>
    <system-preference-input-modal :showModal="showPreferenceInputModal" :preferences="DefinedStandards"
      preference-key="defined_standards" @close-modal="showPreferenceInputModal = false"
      @input-preference="createPreference($event)"
      @remove-preference="removePreference($event)"></system-preference-input-modal>
    <whats-app-login-modal v-if="showWhatsappLoginModal" :showModal="showWhatsappLoginModal"
    :selectedChat="selectedChat"
      :APIStatus="APIStatus[selectedChat]" @close-modal="showWhatsappLoginModal = false"></whats-app-login-modal>
  </el-card>
</template>
<script>
import { ElNotification } from "element-plus";
import { promptOnCenter } from "../utils//notifiers";
import SystemPreferenceInputModal from "../views/modals/SystemPreferenceInputModal.vue";
import SystemPreferenceContactsModal from "../views/modals/SystemPreferenceContactsModal.vue";
import WhatsAppLoginModal from "./modals/WhatsAppLoginModal.vue";
import BaseInput from "../components/BaseInput.vue";
import SystemService from "../services/system";
const contactIndexKeys = [
  "contact_to_receive_whatsapp_alerts",
  "contact_to_receive_whatsapp_alerts1",
  "contact_to_receive_whatsapp_alerts2"
]
export default {
  components: {
    SystemPreferenceContactsModal,
    SystemPreferenceInputModal,
    WhatsAppLoginModal,
    BaseInput,
  },
  name: "SystemPreferences",
  computed: {
    SelectedContacts() {
      if (this.preferences) {
        const contactPreferences = this.preferences.filter(
          (e) => contactIndexKeys.includes(e.key)
        );
        if (contactPreferences?.length)
          return this?.contacts?.filter((c) =>
            contactPreferences?.some((p) => p.value === c.uid)
          );
      }

      return [];
    },
    SelectedContactsGroupedByChat() {
      if (this.preferences) {
        const contactPreferences = this.preferences.filter(
          (e) => e.key === "contact_to_receive_whatsapp_alerts"
        );
        const contactPreferences2 = this.preferences.filter(
          (e) => e.key === "contact_to_receive_whatsapp_alerts1"
        );
        const contactPreferences3 = this.preferences.filter(
          (e) => e.key === "contact_to_receive_whatsapp_alerts2"
        );

        return [contactPreferences, contactPreferences2, contactPreferences3]
      }

      return [];
    },
    DefinedStandards() {
      return this?.preferences?.filter((p) => p.key === "defined_standards");
    },
    Printers() {
      return this.printers || [];
    },
    WhatsAppStatusTypes() {
      return {
        OFFLINE: "warning",
        PROBLEMA: "danger",
        CONECTADO: "success",
      };
    },
    WhatsAppAPIStatus() {
      const self = this;
      return this.APIStatus.map(status => self.getAPIStatus(status));
    },
    can() {
      return this.$store.state.auth.can;
    },
  },
  mounted() {
    this.fetchContacts();
    this.keepFetchingAPIStatus();
    this.fetchPreferences();
    this.fetchPrinters();
  },
  beforeUnmount() {
    if (this.updateInterval) clearInterval(this.updateInterval);

    this.socketConection?.close();
  },
  methods: {
    canAny() {
      for (let a in arguments) if (this.can(arguments[a])) return true;

      return false;
    },
    getAPIStatus(apiStatus) {
      let status = "";
      if (!apiStatus) return "CARREGANDO";
      switch (apiStatus.status) {
        case "desconnectedMobile":
        case "not connected":
        case "deviceNotConnected":
        case "serverWssNotConnected":
        case "successPageWhatsapp":
        case "waitForLogin":
        case "notLogged":
        case "initWhatsapp":
          status = "OFFLINE";
          break;
        case "browserClose":
        case "initBrowser":
        case "connectBrowserWs":
        case "openBrowser":
        case "error":
        case "qrReadFail":
        case "errorPageWhatsapp":
        case "noOpenBrowser":
        case "autocloseCalled":
          status = "PROBLEMA";
          break;
        //case "chatsAvailable":
        //case "waitChat":
        //case "successChat":
        //case "qrReadSuccess":
        //case "deleteToken":
        //case "isLogged":
        default:
          status = "CONECTADO";
          break;
      }

      return status;
    },
    async openEditPrinter(_printer, field = "name") {
      try {
        if (_printer?.uid) {
          const printerEdit = await this.getPrintName(
            field === "name" ? _printer?.name : _printer?.ip,
            field
          );

          const { printer, error } = await SystemService()
            .Printers(_printer.uid)
            .update({ [field]: printerEdit });

          if (printer) ElNotification.success(printer.message);
          else ElNotification.error(error.message);

          this.fetchPrinters();
        }
      } catch (e) {
        console.error(e);
      }
    },
    async openAddPrinter() {
      const printerName = await this.getPrintName();

      const { printer, error } = await SystemService()
        .Printers()
        .create({ name: printerName });

      if (printer) ElNotification.success(printer.message);
      else ElNotification.error(error.message);

      this.fetchPrinters();
    },
    async getPrintName(name, field = "name") {
      try {
        const { value: printName } = await promptOnCenter(
          field === "name"
            ? "Insira o nome da impressora"
            : "Insira o IP da impressora",
          "Impressora",
          {
            confirmButtonText: "Salvar",
            cancelButtonText: "Cancelar",
            inputValue: name,
          }
        );

        return printName;
      } catch (e) {
        return null;
      }
    },
    async deletePrinter(_printer) {
      const { printer, error } = await SystemService()
        .Printers(_printer?.uid)
        .delete();

      if (printer) ElNotification.success(printer.message);
      else ElNotification.error(error.message);

      this.fetchPrinters();
    },
    async fetchWhatsAppStatus() {
      const [res1, res2, res3] = await Promise.all(
        [
          SystemService().WhatsApp().status({ chat_id: 0 }),
          SystemService().WhatsApp().status({ chat_id: 1 }),
          SystemService().WhatsApp().status({ chat_id: 2 })
        ])

      const { status: status1 } = res1 || {};
      const { status: status2 } = res2 || {};
      const { status: status3 } = res3 || {};

      this.APIStatus = [status1, status2, status3];
    },
    keepFetchingAPIStatus() {
      if (this.updateInterval) clearInterval(this.updateInterval);
      this.updateInterval = setInterval(this.fetchWhatsAppStatus, 1000);
    },
    selectContact(selectContactEvent) {
      const { chatIndex, contactId } = selectContactEvent;
      const contactPreference = this.preferences?.find((p) => p.value === contactId && p.key === contactIndexKeys[chatIndex]);
      if (contactPreference) {
        this.removePreference(contactPreference);
      } else {
        this.createPreference({
          key: contactIndexKeys[chatIndex],
          value: contactId,
        });
      }
    },

    getPreferenceByToken(token) {
      const preferenceToken = this.preferences?.find((p) => p.key === token);
      if (preferenceToken) return preferenceToken;

      const newToken = {
        key: token,
        value: "",
      };
      this.preferences?.push(newToken);
      return newToken;
    },
    async fetchPreferences() {
      this.isLoading = true;

      const { preferences } = await SystemService().Preferences().index();

      if (preferences) this.preferences = preferences;

      this.isLoading = false;
    },
    async fetchPrinters() {
      const { printers } = await SystemService().Printers().index();

      if (printers) this.printers = printers;
    },
    async createPreference(p) {
      const { preference } = await SystemService().Preferences().create(p);

      if (preference)
        ElNotification.success({
          title: "Configurações atualizadas com sucesso",
        });
      else
        ElNotification.error({
          title: "Ocorreu um erro ao salvar as configurações",
        });

      this.fetchPreferences();
    },
    setPreference(p) {
      if ("uid" in p) this.updatePreference(p);
      else this.createPreference(p);
    },
    async updatePreference(p) {
      const { preference } = await SystemService().Preferences(p.uid).update(p);

      if (preference)
        ElNotification.success({
          title: "Configurações atualizadas com sucesso",
        });
      else
        ElNotification.error({
          title: "Ocorreu um erro ao salvar as configurações",
        });
    },
    async removePreference(p) {
      if (p) {
        const { preference } = await SystemService()
          .Preferences(p.uid)
          .delete();

        if (preference)
          ElNotification.success({
            title: "Configurações atualizadas com sucesso",
          });
        else
          ElNotification.error({
            title: "Ocorreu um erro ao salvar as configurações",
          });

        this.fetchPreferences();
      }
    },
    openContactPreferenceModal() {
      this.showContactPreferenceModal = true;
    },
    openPreferenceInputModal() {
      this.showPreferenceInputModal = true;
    },
    openShowWhatsappLoginModal(statusIndex) {
      this.selectedChat = statusIndex;
      return (this.showWhatsappLoginModal = true);
    },
    fetchContacts() {
      fetch(`${this.$store.state.apiUrl}contacts`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then(
          (json) => (this.contacts = json?.filter((c) => c.name && c.phone))
        )
        .finally(() => (this.isLoading = false));
    },
  },
  data() {
    return {
      dateFormatter: new Intl.DateTimeFormat("pt-BR", { month: "short" }),
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      selectedChat: 0,
      showPreferenceInputModal: false,
      printers: null,
      updateInterval: null,
      showContactPreferenceModal: false,
      contacts: null,
      showWhatsappLoginModal: false,
      isLoading: false,
      APIStatus: null,
      externalKeys: {},
      preferences: null,
      socketConection: null,
    };
  },
};
</script>
<style scoped>
.el-row {
  margin-bottom: 8px;
}

.m-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.gap-2 {
  gap: 2rem;
}
</style>
