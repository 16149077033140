<template>
  <el-dialog
    title="Escaneie o QRCode para conectar ao dispositivo"
    v-model="shouldShow"
    v-loading="!qrcode"
    width="40%"
  >
    <div>
      <el-row
        type="flex"
        justify="center"
        v-if="hasQRCode && WhatsAppAPIStatus !== 'CONECTADO'"
      >
        <vue-qr-code :value="qrcode"></vue-qr-code>
      </el-row>
      <img v-else :src="'data:image/png;base64,' + whatsappWebScreenshot" />
      <el-divider></el-divider>
      <el-row type="flex" justify="end">
        <el-button
          type="danger"
          v-if="WhatsAppAPIStatus === 'CONECTADO'"
          v-loading="isLoadingDisconnect"
          @click="disconnectWhatsApp()"
          >DESCONECTAR</el-button
        >
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import SystemService from "../../services/system";
import VueQrCode from "@chenfengyuan/vue-qrcode";
export default {
  name: "WhatsAppLoginModal",
  props: ["showModal", "APIStatus", "selectedChat"],
  data: () => {
    return {
      qrcode: null,
      connection: {},
      isLoadingDisconnect: false,
      whatsappWebScreenshot: "",
    };
  },
  components: { VueQrCode },
  emits: ["close-modal"],
  watch: {
    showModal(v) {
      if (v) this.fetchQRCode();
    },
    APIStatus() {
      this.fetchQRCode();
      this.fetchWAWScreenshot();
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    hasQRCode() {
      return "qrcode" in this.connection;
    },
    WhatsAppAPIStatus() {
      let status = "";
      if (!this.connection) return "CARREGANDO";
      switch (this.connection?.status) {
        case "desconnectedMobile":
        case "not connected":
        case "deviceNotConnected":
        case "serverWssNotConnected":
        case "successPageWhatsapp":
        case "waitForLogin":
        case "notLogged":
          status = "OFFLINE";
          break;
        case "browserClose":
        case "initBrowser":
        case "connectBrowserWs":
        case "initWhatsapp":
        case "openBrowser":
        case "qrReadFail":
        case "errorPageWhatsapp":
        case "noOpenBrowser":
        case "error":
        case "deleteToken":
        case "autocloseCalled":
          status = "PROBLEMA";
          break;
        //case "chatsAvailable":
        //case "waitChat":
        //case "successChat":
        //case "qrReadSuccess":
        //case "isLogged":
        default:
          status = "CONECTADO";
          break;
      }

      return status;
    },
  },
  methods: {
    async fetchWAWScreenshot() {
      const {
        response: { screenshot },
      } = await SystemService().WhatsApp().screenshot({chat_id: this.selectedChat});

      this.whatsappWebScreenshot = screenshot;
    },
    async fetchQRCode() {
      const { connection } = await SystemService().WhatsApp().connect({chat_id: this.selectedChat});

      if (connection) this.qrcode = connection.qrcode;

      this.connection = connection || {};
    },
    async disconnectWhatsApp() {
      this.isLoadingDisconnect = true;
      const { status } = await SystemService().WhatsApp().disconnect({chat_id: this.selectedChat});
      this.isLoadingDisconnect = false;
      if (status) {
        this.fetchQRCode();
      }
    },
  },
};
</script> 