<template>
  <el-dialog v-model="shouldShow" title="Lista de configurações">
    <div v-if="hasError">
      <el-row type="flex" align="center" justify="center">
        <h4>Ocorreu um erro a obter os dados do envio</h4>
      </el-row>
    </div>
    <el-row
      v-for="c in preferences_"
      :key="c"
      type="flex"
      justify="space-between"
    >
      <el-col :md="16" align="left">
        <el-row type="flex" justify="start"
          ><span>{{ c.value }}</span></el-row
        >
      </el-col>

      <el-button onlyIcon type="danger" @click="removePreference(c)" circle>
        <i class="el-icon-delete"></i
      ></el-button>
    </el-row>
    <el-row v-if="!preferences" type="flex" justify="center">
      <small class="text-muted">Nenhuma configuração foi cadastrado</small>
    </el-row>
    <el-row v-if="showInputPreference" type="flex" justify="center">
      <base-input
        v-model="inputPreference"
        @keyup.enter="savePreference"
        hint="Digite e pressione enter"
      ></base-input>
    </el-row>
    <template #footer>
      <el-row justify="end" type="flex">
        <el-button
          type="text"
          class="button"
          @click="showInputPreference = true"
          >Adicionar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import { ElLoading } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";
export default {
  props: ["preferences", "preferenceKey", "showModal"],
  emits: ["close-modal", "input-preference", "remove-preference"],
  components: { BaseInput },
  data() {
    return {
      isLoading: true,
      hasError: false,
      preferences_: this.preferences,
      inputPreference: null,
      showInputPreference: false,
    };
  },
  watch: {
    preferences(v) {
      this.preferences_ = v || [];
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    savePreference() {
      this.$emit("input-preference", {
        key: this.preferenceKey,
        value: this.inputPreference,
      });
      this.inputPreference = "";
      this.showInputPreference = false;
    },
    removePreference(p) {
      this.$emit("remove-preference", p);
    },
  },
  name: "SystemInputPrefencesModal",
};
</script>
<style>
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.text-muted {
  color: grey !important;
  margin-top: 5px !important;
}
</style>