<template>
  <el-dialog v-model="shouldShow" title="Lista de contatos enviados">
    <div v-if="hasError">
      <el-row type="flex" align="center" justify="center">
        <h4>Ocorreu um erro a obter os dados do envio</h4>
      </el-row>
    </div>
    <el-loading v-loading="isLoading" v-else-if="isLoading"> </el-loading>
    <el-row v-for="c in contacts" :key="c" type="flex" justify="space-between">
      <el-col :md="16" align="left">
        <el-row type="flex" justify="start"><span>{{ c.name }}</span></el-row>
        <el-row type="flex" justify="start">
          <h6 class="text-muted">
            {{ c.phone }}
          </h6>
        </el-row>
      </el-col>
      <el-checkbox-group :model-value="isSelectedContact(c)" size="small">
        <el-checkbox-button label="Principal" value="0" @change="selectContact($event, c, 0)" />
        <el-checkbox-button label="Relatórios Gerenciais" value="1" @change="selectContact($event, c, 1)" />
        <el-checkbox-button label="Outros" value="2" @change="selectContact($event, c, 2)" />
      </el-checkbox-group>
    </el-row>
    <el-row v-if="!contacts" type="flex" justify="center">
      <small class="text-muted">Nenhum contato foi cadastrado</small>
    </el-row>
  </el-dialog>
</template>
<script>
import { ElLoading } from "element-plus";
export default {
  props: ["contacts", "selectedContacts", "showModal"],
  emits: ["close-modal", "select-contact"],
  components: { ElLoading },
  data() {
    return {
      isLoading: true,
      hasError: false,
      bid: null,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    isSelectedContact(contact) {
      const chatLabels = ["Principal", "Relatórios Gerenciais", "Outros"];
      return chatLabels.filter((label, id) => this.getContactIsSelectedInChatId(id, contact.uid));
    },
    selectContact(e, contact, chatIndex) {
      this.$emit("select-contact", {chatIndex, contactId: contact.uid});
    },
    getContactIsSelectedInChatId(chatId, contactId){
      return !!this.selectedContacts[chatId]?.find(c => c.value === contactId);
    }
  },
  name: "SystemPreferenceContactsModal",
};
</script>
<style>
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.text-muted {
  color: grey !important;
  margin-top: 5px !important;
}
</style>